import URLWatcher from "../../../libs/v2/diga/scripts/URLWatcher";

import getInsuranceList from './fetchInsuranceList.js';

(function ($) {

    // **** flexslider plugin *******

    var $window = $(window),
        flexslider = {
            vars: {}
        };

    function getScreenSize() {
        return (window.innerWidth < 768) ? 1 :
            (window.innerWidth < 1280 && window.innerWidth >= 768) ? 2 : 3;
    }

    $(window).on('load', function () {
        $('.flexslider-1').flexslider({
            slideshow: false,
            animation: "slide",
            animationLoop: true,
            itemWidth: 230,
            itemMargin: 30,
            minItems: 1,
            maxItems: 1,
            move: getScreenSize(),
            startAt: 0,
            start: function (slider) {
                flexslider = slider;
            }
        });
        $('.flexslider-2').flexslider({
            slideshow: false,
            animation: "slide",
            animationLoop: true,
            itemWidth: 230,
            itemMargin: 30,
            minItems: 1,
            maxItems: 2,
            move: getScreenSize(),
            startAt: 0,
            start: function (slider) {
                flexslider = slider;
            }
        });

        $('.flexslider-3').flexslider({
            slideshow: false,
            animation: "slide",
            animationLoop: true,
            itemWidth: 230,
            itemMargin: 30,
            minItems: getScreenSize(),
            maxItems: getScreenSize(),
            move: getScreenSize(),
            startAt: 0,
            start: function (slider) {
                flexslider = slider;
            }
        });
    });

    $window.resize(function () {
        var gridSize = getScreenSize();

        flexslider.vars.minItems = gridSize;
        flexslider.vars.maxItems = gridSize;
    });

    $(document).ready(function () {
        // ********** jQuery plugins ************ 
        
        if ($('body.page_1').length > 0 || $('body.page_5').length > 0) {
            $('body').formRezept(document.bob.rezept);
        }

        // PAGE12 PATIENTS FORMS
        if ($('body.page_12').length > 0) {
            $('body').digaAccess();
        }

        // PAGE16 PATIENTS FORMS
        if ($('body.page_16').length > 0) {
            $('body').digaRenewal();
        }

        $('body').scrollToAnchors();

        $('.glyphicons').glyphClone();

        $('.jumbotron a[title^=button], .passage a[title^=button], .usp-content a[title^=button], .footer a[title^=button]').mdLinkToButton();

        $('.btn').createTrackingClass();

        $('body').etrackerButtonEvents();

        $('.body-content').linkTap();

        $(window).scrollTopArrow();

        $('.navbar').menuScrollAnimations();

        $('.navbar').menuActive();

        $('body').fadePage();

        $('.bob-frame-show').bobframe();

        new URLWatcher();
    });

}(jQuery));