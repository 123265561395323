import _ from 'lodash';
import '../styles/main.scss';

import 'jquery';

// scripts that are copied from the libs folder.
// This is done via webpack copy plugin in webpack.common.js
// the files are copied into the "fromLibs" folder.
import '../scripts/fromLibs/jquery.scrollTo.min.js';

//Bootstrap
// import 'bootstrap';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/index';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/util';

// Bob scripts form Libs (../../libs/v2/diga/scripts/)
import '../scripts/fromLibs/bobform_contact_pro2.js';
import '../scripts/fromLibs/bobform_hubspot_contact_utm.js';
import '../scripts/fromLibs/bobform_mail_contact_flyer.js';
import '../scripts/fromLibs/bobform_mail_access.js';
import '../scripts/fromLibs/bobform_mail_contact.js';
import '../scripts/fromLibs/bobform_contact_zen.js';
import '../scripts/fromLibs/bobform_mail_survey.js';
import '../scripts/fromLibs/bobform_mail_help.js';
import '../scripts/fromLibs/bobform_rezept_contact.js';
import '../scripts/fromLibs/bobframe6.js';
import '../scripts/fromLibs/bobmsg4.js';
import '../scripts/fromLibs/diga_access2.js'; 
import '../scripts/fromLibs/diga_renewal2.js';
import '../scripts/fromLibs/fadePage.js';
import '../scripts/fromLibs/flexslider.js';
import '../scripts/fromLibs/glyphicons.js';
import '../scripts/fromLibs/intersectionObserver.js';
import '../scripts/fromLibs/linkTap.js';
import '../scripts/fromLibs/md_buttons.js';
import '../scripts/fromLibs/menu_active.js';
import '../scripts/fromLibs/menu2.js';
import '../scripts/fromLibs/scrollTo_anchors.js';
import '../scripts/fromLibs/scrollTop_arrow.js';
import '../scripts/fromLibs/spinner.js';
import '../scripts/fromLibs/util2.js';
import '../scripts/fromLibs/tracking_class_creator.js'
import '../scripts/fromLibs/etrackerButtonEvents.js'
import '../scripts/fromLibs/globals_digas.js';

// Project specific scripts
import '../scripts/main.js';
import '../scripts/fetchInsuranceList.js';
import '../scripts/displayUploadedFileName.js';
import '../scripts/utm.js';
//import '../scripts/reels.js';
//import '../scripts/emailShowHide.js';